#businessCooperation {
  height: 100vh;
  background: #fff;
  overflow-y: auto;
}
#businessCooperation .container {
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#businessCooperation .containerDetail_box {
  width: 1168px;
  padding-top: 3rem;
  text-align: center;
}
#businessCooperation .containerDetail_box h1 {
  font-size: 34px;
}
#businessCooperation .container {
  width: 1168px;
  margin: 0 auto;
}
#businessCooperation .container .container_box {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 3rem;
}
#businessCooperation .container .container_box div {
  border: 1px solid #edf6ff;
  border-radius: 0.4rem;
  padding: 30px 30px;
  background: #fff;
  width: 280px;
}
#businessCooperation .container .container_box div .threeTitle {
  font-size: 20px;
  line-height: 20px;
  padding-top: 20px;
}
#businessCooperation .container .container_box div .threeContent {
  font-size: 14px;
  color: #5c6b84;
  margin-top: 16px;
  text-align: left;
}
#businessCooperation .container .container_box > div:hover {
  cursor: pointer;
  box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
}
#businessCooperation .levelORa {
  width: 100%;
  background: #f8fafd;
  padding: 60px 0;
}
#businessCooperation .levelORa .levelPublic {
  width: 1168px;
  margin: 0 auto;
}
#businessCooperation .levelORa .levelPublic h1 {
  font-size: 34px;
  color: #3f4652;
  text-align: center;
}
#businessCooperation .levelORa .levelPublic p {
  font-size: 17px;
  color: #5c6b84;
  text-align: center;
  padding: 16px 0 40px;
}
#businessCooperation .levelORa .levelPublic .levelPublicImgBox {
  display: flex;
}
#businessCooperation .LevelCertification {
  width: 100%;
  background: #fff;
  padding: 60px 0;
}
#businessCooperation .LevelCertification .LevelCertificationContent {
  width: 1168px;
  margin: 0 auto;
}
#businessCooperation .LevelCertification .LevelCertificationContent h1 {
  font-size: 34px;
  color: #3f4652;
  text-align: center;
}
#businessCooperation .LevelCertification .LevelCertificationContent .p {
  font-size: 17px;
  color: #5c6b84;
  text-align: center;
  padding: 16px 0 16px;
}
#businessCooperation .LevelCertification .LevelCertificationContent .levelPublicImgBox {
  display: flex;
  justify-content: space-between;
  padding-top: 36px;
}
#businessCooperation .LevelCertification .LevelCertificationContent .levelPublicImgBox > div {
  border: 1px solid #edf6ff;
  border-radius: 0.4rem;
  padding: 30px 30px;
  background: #fff;
  width: 221px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#businessCooperation .LevelCertification .LevelCertificationContent .levelPublicImgBox > div p {
  padding-top: 20px;
}
