



































































































































#businessCooperation {
  height: 100vh;
  background: #fff;
  overflow-y: auto;
  .container {
    background: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .containerDetail_box {
    width: 1168px;
    padding-top: 3rem;
    text-align: center;
    h1 {
      font-size: 34px;
    }
  }
  .container {
    width: 1168px;
    // text-align: center;
    margin: 0 auto;
    .container_box {
      display: flex;
      justify-content: space-between;
      padding: 2rem 0 3rem;

      div {
        border: 1px solid #edf6ff;
        border-radius: 0.4rem;
        padding: 30px 30px;
        background: #fff;
        width: 280px;
        .threeTitle {
          font-size: 20px;
          line-height: 20px;
          padding-top: 20px;
        }
        .threeContent {
          font-size: 14px;
          color: #5c6b84;
          margin-top: 16px;
          text-align: left;
        }
      }
      > div:hover {
        cursor: pointer;
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
      }
    }
  }

  .levelORa {
    width: 100%;
    background: #f8fafd;
    padding: 60px 0;
    .levelPublic {
      width: 1168px;
      margin: 0 auto;
      h1 {
        font-size: 34px;
        color: #3f4652;
        text-align: center;
      }
      p {
        font-size: 17px;
        color: #5c6b84;
        text-align: center;
        padding: 16px 0 40px;
      }
      .levelPublicImgBox {
        display: flex;
      }
    }
  }
  .LevelCertification {
    width: 100%;
    background: #fff;
    padding: 60px 0;
    .LevelCertificationContent {
      width: 1168px;
      margin: 0 auto;
      h1 {
        font-size: 34px;
        color: #3f4652;
        text-align: center;
      }
      .p {
        font-size: 17px;
        color: #5c6b84;
        text-align: center;
        padding: 16px 0 16px;
      }
      .levelPublicImgBox {
        display: flex;
        justify-content: space-between;
        padding-top: 36px;
        > div {
          border: 1px solid #edf6ff;
          border-radius: 0.4rem;
          padding: 30px 30px;
          background: #fff;
          width: 221px;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-direction: column;
          p {
            padding-top: 20px;
          }
        }
      }
    }
  }
}
